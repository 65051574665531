export const getPopularityScore = (popularity) => {
  if (popularity <= 5) {
    return {
      score: popularity,
      color: "text-red-500",
      label: "Not Popular"
    };
  } else if (popularity <= 15) {
    return {
      score: popularity,
      color: "text-orange-500",
      label: "Somewhat Popular"
    };
  } else {
    return {
      score: popularity,
      color: "text-green-500",
      label: "Popular"
    };
  }
};