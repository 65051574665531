import React from 'react';
import { motion } from 'framer-motion';

const Imprint = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-black py-20 px-4"
    >
      <div className="max-w-3xl mx-auto">
        <motion.h1 
          initial={{ y: 20 }}
          animate={{ y: 0 }}
          className="text-4xl md:text-5xl font-bold text-gradient mb-12"
        >
          Imprint
        </motion.h1>

        <div className="space-y-8 text-white/80">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-accent">Information in accordance with Section 5 DDG</h2>
            <p>Creator: Logofoxofficial - Mike Aberle</p>
            <p>Europaring 90</p>
            <p>53757 Sankt Augustin</p>
            <p>Germany</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-accent">Legal Representative</h2>
            <p>Mike Aberle</p>
            <p>Email: business@logofoxofficial.de</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-accent">Disclaimer</h2>
            <p className="text-white/60">
              This website is operated by LogoFox Music. The content of this website is for general information purposes only.
            </p>
          </section>
        </div>
      </div>
    </motion.div>
  );
};

export default Imprint;