import { motion } from "framer-motion";
import React, { useState } from "react";
import { ChevronDown } from "lucide-react";
import { useSpotifyTracks } from "../utils/spotify";
import FeaturedTracks from "./music/FeaturedTracks";
import DiscographyDialog from "./music/DiscographyDialog";

const Music = () => {
  const [hoveredTrack, setHoveredTrack] = useState(null);
  const [showDiscography, setShowDiscography] = useState(false);
  
  const { data: tracks, isLoading, isError } = useSpotifyTracks({
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30,
  });

  if (isError) {
    return (
      <div className="min-h-screen bg-primary py-20 relative overflow-hidden flex items-center justify-center">
        <div className="text-center space-y-4">
          <h2 className="text-3xl font-bold text-accent">Unable to Load Releases</h2>
          <p className="text-white/60">Please try again later</p>
        </div>
      </div>
    );
  }

  return (
    <div id="music-section" className="min-h-screen bg-primary py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-radial from-black via-black to-accent/20 opacity-50" />
      
      <div className="absolute inset-0">
        {Array.from({ length: 30 }).map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-1 h-1 bg-accent rounded-full"
            animate={{
              x: ["0vw", `${Math.random() * 100}vw`],
              y: ["0vh", `${Math.random() * 100}vh`],
              scale: [1, 0],
              opacity: [0.8, 0],
            }}
            transition={{
              duration: Math.random() * 5 + 5,
              repeat: Infinity,
              ease: "linear",
            }}
            style={{
              left: `${Math.random() * 100}vw`,
              top: `${Math.random() * 100}vh`,
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <span className="text-accent text-sm uppercase tracking-wider font-bold">Music</span>
          <h2 className="text-5xl md:text-6xl font-bold mt-2 mb-8 text-gradient">Latest Releases</h2>
          
          <FeaturedTracks
            tracks={tracks}
            isLoading={isLoading}
            hoveredTrack={hoveredTrack}
            setHoveredTrack={setHoveredTrack}
          />

          <motion.button
            onClick={() => setShowDiscography(true)}
            className="mt-12 flex items-center gap-2 mx-auto bg-accent/10 hover:bg-accent/20 text-accent border border-accent/50 px-6 py-3 rounded-xl transition-all duration-300 group"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <span>View Full Discography</span>
            <ChevronDown className="w-4 h-4 group-hover:translate-y-1 transition-transform" />
          </motion.button>
        </motion.div>
      </div>

      <DiscographyDialog
        showDiscography={showDiscography}
        setShowDiscography={setShowDiscography}
        tracks={tracks}
      />
    </div>
  );
};

export default Music;