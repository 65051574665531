import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { cn } from "@/lib/utils";
import { Star, Sparkles } from "lucide-react";
import { memo } from 'react';
import { getPopularityScore } from "../../utils/popularity";
import { format } from "date-fns";

const TrackCard = memo(({ track, isHovered, onHover, onLeave }) => {
  const popularityInfo = getPopularityScore(track.popularity || 0);

  const formatReleaseDate = (date) => {
    return format(new Date(date), "do MMMM yyyy");
  };

  return (
    <motion.a
      href={track.spotifyUrl}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      className={cn(
        "block p-6 rounded-2xl border border-accent/20 hover:border-accent/50 transition-all duration-300 group bg-gradient-to-r from-black to-accent/5",
        isHovered && "border-accent/50 shadow-lg shadow-accent/5"
      )}
      whileHover={{ y: -5 }}
      whileTap={{ scale: 0.98 }}
      loading="lazy"
    >
      <div className="flex items-center gap-6">
        {track.coverUrl ? (
          <img 
            src={track.coverUrl} 
            alt={track.title} 
            className="w-16 h-16 md:w-20 md:h-20 rounded-xl object-cover"
            loading="lazy"
            width={80}
            height={80}
          />
        ) : (
          <div className="w-16 h-16 md:w-20 md:h-20 rounded-xl bg-accent/10 flex items-center justify-center">
            <FontAwesomeIcon icon={faSpotify} className="w-8 h-8 text-accent/50" />
          </div>
        )}
        <div className="flex-1">
          <div className="flex items-center gap-3 mb-2">
            <h3 className="text-xl md:text-2xl font-bold group-hover:text-gradient transition-all duration-300">
              {track.title}
            </h3>
            {track.isNewest && (
              <span className="flex items-center gap-1 text-xs bg-accent/20 text-accent px-3 py-1 rounded-full font-medium">
                <Sparkles className="w-3 h-3" />
                NEW
              </span>
            )}
            {track.isMostPopular && (
              <span className="flex items-center gap-1 text-xs bg-accent/20 text-accent px-3 py-1 rounded-full font-medium">
                <Star className="w-3 h-3" />
                MOST POPULAR
              </span>
            )}
            {track.isMostStreamed && (
              <span className="flex items-center gap-1 text-xs bg-accent/20 text-accent px-3 py-1 rounded-full font-medium">
                <Star className="w-3 h-3" />
                MOST STREAMED
              </span>
            )}
          </div>
          <div className="flex items-center gap-4 text-sm text-white/60">
            <span>{formatReleaseDate(track.releaseDate)}</span>
            <div className="flex items-center gap-1">
              <FontAwesomeIcon icon={faSpotify} className={popularityInfo.color} />
              <span className={popularityInfo.color}>
                {popularityInfo.score} Points ({popularityInfo.label})
              </span>
            </div>
          </div>
        </div>
      </div>
    </motion.a>
  );
});

TrackCard.displayName = 'TrackCard';

export default TrackCard;