import { motion } from "framer-motion";
import { Music4, Mic2, Pen, User } from "lucide-react";
import { Link } from "react-router-dom";

const Hero = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80; // Header offset
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
      {/* Enhanced Dynamic Background */}
      <div className="absolute inset-0 bg-black">
        <div className="absolute inset-0 bg-gradient-to-b from-accent/20 via-primary to-black opacity-30" />
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
          className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-accent via-primary to-black opacity-40"
        />
      </div>
      
      {/* Animated Sound Waves */}
      <div className="absolute inset-0 flex items-center justify-center overflow-hidden opacity-20">
        {[...Array(30)].map((_, i) => (
          <motion.div
            key={i}
            className="w-1 mx-0.5 bg-gradient-to-t from-accent to-accent/10"
            animate={{
              height: ['20px', `${Math.random() * 100 + 20}px`, '20px'],
              opacity: [0.3, 0.8, 0.3],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              delay: i * 0.1,
              ease: "easeInOut"
            }}
          />
        ))}
      </div>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="relative z-10 text-center space-y-8 px-4"
      >
        {/* Role Icons */}
        <div className="flex justify-center gap-8 mb-12">
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="flex flex-col items-center gap-2"
          >
            <div className="p-4 rounded-full bg-accent/10 backdrop-blur-sm border border-accent/20">
              <Music4 className="w-8 h-8 text-accent" />
            </div>
            <span className="text-sm text-gray-400">Producer</span>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="flex flex-col items-center gap-2"
          >
            <div className="p-4 rounded-full bg-accent/10 backdrop-blur-sm border border-accent/20">
              <Pen className="w-8 h-8 text-accent" />
            </div>
            <span className="text-sm text-gray-400">Songwriter</span>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="flex flex-col items-center gap-2"
          >
            <div className="p-4 rounded-full bg-accent/10 backdrop-blur-sm border border-accent/20">
              <Mic2 className="w-8 h-8 text-accent" />
            </div>
            <span className="text-sm text-gray-400">Lyricist</span>
          </motion.div>
        </div>
        
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="text-8xl md:text-9xl font-bold mb-16 text-gradient leading-none tracking-tight relative z-20"
        >
          LogoFox
        </motion.h1>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="text-2xl md:text-3xl text-gray-400 mb-12 max-w-2xl mx-auto"
        >
          Transforming emotions into sonic landscapes, one track at a time 🎵
        </motion.p>
        
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7, duration: 0.5 }}
          className="flex gap-6 justify-center items-center flex-col md:flex-row"
        >
          <button 
            onClick={() => scrollToSection("music-section")}
            className="group relative px-8 py-3 overflow-hidden rounded-full bg-accent/10 backdrop-blur-sm border border-accent/20 hover:border-accent transition-all duration-300"
          >
            <div className="absolute inset-0 w-0 bg-accent transition-all duration-300 ease-out group-hover:w-full" />
            <span className="relative text-lg font-medium group-hover:text-white transition-colors flex items-center gap-2">
              Explore My Music
              <Music4 className="w-5 h-5" />
            </span>
          </button>
          
          <button 
            onClick={() => scrollToSection("about-section")}
            className="group relative px-8 py-3 overflow-hidden rounded-full bg-black/30 backdrop-blur-sm hover:bg-black/40 transition-all duration-300"
          >
            <span className="relative text-lg font-medium text-gray-300 group-hover:text-white transition-colors flex items-center gap-2">
              My Story
              <User className="w-5 h-5" />
            </span>
          </button>
          
          <Link 
            to="/label"
            className="text-accent/70 hover:text-accent transition-all duration-300 text-sm uppercase tracking-wider font-medium hover:scale-105 transform flex items-center gap-2"
          >
            <span className="w-8 h-[1px] bg-accent/50" />
            Join The Movement
            <span className="w-8 h-[1px] bg-accent/50" />
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Hero;