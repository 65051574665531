import { motion } from "framer-motion";
import { Music4 } from "lucide-react";
import { Link } from "react-router-dom";

const artists = [
  {
    name: "Lyssaria",
    image: "/images/artists/lyssaria.png",
    description: "Lyssaria is an American vocalist with a voice that echoes raw emotion and intensity. Hailing from the depths of Seattle's alternative scene, this 24-year-old artist blends the edge of Alternative Rock with a hauntingly powerful twist of Metal, creating music that resonates deeply with listeners. Known for her introspective lyrics and atmospheric soundscapes, Lyssaria explores themes of inner conflict, resilience, and redemption. Her music is a journey through darkness and light, a reflection of her own life's struggles and triumphs. A true newcomer to the industry, she has yet to step into the spotlight fully but is already capturing attention for her unique, soul-stirring approach. With each release, Lyssaria invites listeners to dive into a world of emotional depth and unapologetic honesty, marking her as one to watch in the alternative music scene.",
    genres: ["Ethereal", "Alternative", "Ambient", "Metal"]
  }
];

export const Label = () => {
  return (
    <div className="min-h-screen bg-primary relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-accent/20 via-black to-black opacity-50" />
        {Array.from({ length: 30 }).map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-1 h-1 bg-accent rounded-full"
            animate={{
              x: ["0vw", `${Math.random() * 100}vw`],
              y: ["0vh", `${Math.random() * 100}vh`],
              scale: [1, 0],
              opacity: [0.8, 0],
            }}
            transition={{
              duration: Math.random() * 5 + 5,
              repeat: Infinity,
              ease: "linear",
            }}
            style={{
              left: `${Math.random() * 100}vw`,
              top: `${Math.random() * 100}vh`,
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4 py-20 relative z-10">
        <Link 
          to="/"
          className="inline-flex items-center gap-2 text-accent/70 hover:text-accent transition-all duration-300 mb-12"
        >
          <span className="w-8 h-[1px] bg-accent/50"></span>
          Back to Home
        </Link>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto"
        >
          <div className="flex items-center justify-center mb-12">
            <Music4 className="w-16 h-16 text-accent animate-pulse mr-4" />
            <h1 className="text-6xl font-bold text-gradient">LogoFox Music Label</h1>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
            className="space-y-8 backdrop-blur-sm bg-black/30 p-8 rounded-2xl border border-accent/20"
          >
            <div className="prose prose-invert max-w-none">
              <p className="text-xl leading-relaxed mb-8">
                LogoFox Music is more than just a label - it's an exclusive music network where artists collaborate, 
                support each other, and push the boundaries of creative expression. While we operate as an unofficial 
                label, our impact is felt through the success of artists who have found their voice within our community.
              </p>

              <div className="bg-gradient-to-r from-black to-accent/10 p-6 rounded-xl border border-accent/20 mb-8">
                <h3 className="text-2xl font-bold mb-4 text-gradient">Our Vision</h3>
                <p className="text-lg">
                  We believe in fostering a close-knit network of artists who share our passion for musical innovation 
                  and artistic growth. Each member of our community receives dedicated support, enabling them to focus 
                  on what matters most - creating exceptional music.
                </p>
              </div>

              <div className="bg-gradient-to-r from-black to-accent/10 p-6 rounded-xl border border-accent/20">
                <h3 className="text-2xl font-bold mb-4 text-gradient">Membership Status</h3>
                <p className="text-lg">
                  Currently, LogoFox Music operates as a closed network. While we appreciate the interest from aspiring 
                  artists, we are not accepting applications at this time. Our focus remains on nurturing and developing 
                  our existing roster of talented musicians.
                </p>
              </div>

              <div className="mt-12">
                <h3 className="text-2xl font-bold mb-8 text-gradient text-center">Featured Artists</h3>
                <div className="space-y-8">
                  {artists.map((artist, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.1 * index, duration: 0.5 }}
                      className="bg-black/40 backdrop-blur-sm border border-accent/20 rounded-2xl overflow-hidden hover:border-accent/50 transition-all duration-300 group"
                    >
                      <div className="grid md:grid-cols-2 gap-6 p-6">
                        <div className="relative aspect-square rounded-xl overflow-hidden">
                          <img 
                            src={artist.image} 
                            alt={artist.name}
                            className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
                        </div>
                        <div className="flex flex-col justify-center">
                          <h4 className="text-3xl font-bold mb-4 text-gradient">{artist.name}</h4>
                          <p className="text-gray-300 mb-4">{artist.description}</p>
                          <div className="flex flex-wrap gap-2">
                            {artist.genres.map((genre, i) => (
                              <span 
                                key={i}
                                className="bg-accent/20 text-accent px-3 py-1 rounded-full text-sm font-medium"
                              >
                                {genre}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Label;