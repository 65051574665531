import React from 'react';
import { motion } from "framer-motion";
import * as Dialog from "@radix-ui/react-dialog";
import { cn } from "../../lib/utils";
import { ChevronDown } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { getPopularityScore } from "../../utils/popularity";

const DiscographyDialog = ({ showDiscography, setShowDiscography, tracks }) => {
  return (
    <Dialog.Root open={showDiscography} onOpenChange={setShowDiscography}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-black/90 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        <Dialog.Content 
          className={cn(
            "fixed left-[50%] top-[50%] z-50 w-full max-w-2xl max-h-[80vh] overflow-y-auto translate-x-[-50%] translate-y-[-50%] border border-accent bg-gradient-radial from-black via-black to-accent/20 p-8 shadow-2xl duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-2xl backdrop-blur-sm"
          )}
        >
          <div className="text-center mb-8">
            <h3 className="text-3xl font-bold text-gradient">Full Discography</h3>
            <p className="text-white/60 mt-2">All releases sorted by date</p>
          </div>
          <div className="space-y-4">
            {tracks?.map((track, index) => (
              <motion.a
                key={track.id}
                href={track.spotifyUrl}
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05 }}
                className={cn(
                  "block p-4 rounded-xl border border-accent/20 hover:border-accent/50 cursor-pointer transition-all duration-300 group",
                  index === 0 && "bg-accent/10 border-accent/40"
                )}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    {track.coverUrl && (
                      <img 
                        src={track.coverUrl} 
                        alt={track.title} 
                        className="w-12 h-12 rounded-lg object-cover"
                        loading="lazy"
                        width={48}
                        height={48}
                      />
                    )}
                    <div>
                      <div className="flex items-center gap-2">
                        <h4 className="font-semibold group-hover:text-gradient">{track.title}</h4>
                        {index === 0 && (
                          <span className="text-xs bg-accent/20 text-accent px-2 py-0.5 rounded-full">
                            NEW
                          </span>
                        )}
                      </div>
                      <div className="flex items-center gap-4 text-sm text-white/50">
                        <span>{new Date(track.releaseDate).toLocaleDateString()}</span>
                        <div className="flex items-center gap-4">
                          <div className="flex items-center gap-1">
                            <FontAwesomeIcon icon={faSpotify} className="w-3 h-3" />
                            {(() => {
                              const popularityInfo = getPopularityScore(track.popularity || 0);
                              return (
                                <span className={popularityInfo.color}>
                                  {popularityInfo.score} Points ({popularityInfo.label})
                                </span>
                              );
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ChevronDown className="w-4 h-4 text-white/50 group-hover:text-accent transition-colors" />
                </div>
              </motion.a>
            ))}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default DiscographyDialog;