import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="relative w-full overflow-hidden bg-black py-12">
      {/* Enhanced Waveform Animation */}
      <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
        {[...Array(30)].map((_, i) => (
          <motion.div
            key={i}
            className="w-1 mx-0.5 bg-gradient-to-t from-accent/30 to-accent/10"
            animate={{
              height: ['20px', `${Math.random() * 80 + 20}px`, '20px'],
              opacity: [0.3, 0.8, 0.3],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              delay: i * 0.1,
              ease: "easeInOut"
            }}
          />
        ))}
      </div>

      {/* Glowing Accent Line */}
      <div className="absolute top-0 left-0 right-0 h-px bg-gradient-radial from-accent via-accent/20 to-transparent" />

      {/* Content */}
      <div className="relative z-10 container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div className="text-center md:text-left">
            <h3 className="text-xl font-bold text-gradient mb-4">LogoFox Music</h3>
            <p className="text-white/60">Music Artist</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-bold text-white/90 mb-4">Quick Links</h3>
            <nav className="flex flex-col space-y-2">
              <Link to="/" className="text-white/60 hover:text-accent transition-colors">Home</Link>
              <Link to="/label" className="text-white/60 hover:text-accent transition-colors">Label</Link>
              <Link to="/imprint" className="text-white/60 hover:text-accent transition-colors">Imprint</Link>
            </nav>
          </div>
          <div className="text-center md:text-right">
            <h3 className="text-xl font-bold text-white/90 mb-4">Connect</h3>
            <div className="flex justify-center md:justify-end space-x-4">
              <motion.a 
                href="https://open.spotify.com/artist/1SBKUCSv0lMankDasprEAF?si=qiptpHh7SZaVIt0tTDDUEQ" 
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/60 hover:text-accent transition-colors"
                whileHover={{ scale: 1.1 }}
              >
                Spotify
              </motion.a>
              <motion.a 
                href="https://www.youtube.com/@LogoFoxMusic" 
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/60 hover:text-accent transition-colors"
                whileHover={{ scale: 1.1 }}
              >
                YouTube
              </motion.a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-white/10 pt-8 text-center">
          <p className="text-white/60 text-sm">
            © {new Date().getFullYear()} LogoFox Music. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;