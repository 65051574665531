import { motion } from "framer-motion";
import Hero from "../components/Hero";
import About from "../components/About";
import Music from "../components/Music";
import Timeline from "../components/Timeline";
import Footer from "../components/Footer";
import GenreShowcase from "../components/GenreShowcase";

export const Index = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Hero />
      <About />
      <GenreShowcase />
      <Music />
      <Timeline />
      <Footer />
    </motion.div>
  );
};

export default Index;