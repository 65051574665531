import { motion } from "framer-motion";
import { Music4, Headphones, Radio, Mic2 } from "lucide-react";

const GenreShowcase = () => {
  const genres = [
    {
      name: "Symphonic Metalcore",
      icon: <Music4 className="w-6 h-6" />,
      description: "Where orchestral grandeur meets crushing breakdowns",
      elements: ["Orchestral elements", "Heavy breakdowns", "Melodic passages"]
    },
    {
      name: "Alternative Rock",
      icon: <Headphones className="w-6 h-6" />,
      description: "Pushing boundaries with emotional depth",
      elements: ["Dynamic vocals", "Atmospheric soundscapes", "Powerful choruses"]
    },
    {
      name: "Electronic",
      icon: <Radio className="w-6 h-6" />,
      description: "Digital textures enhancing emotional impact",
      elements: ["Synth layers", "Electronic beats", "Ambient textures"]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-black to-accent/5 py-20 relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-accent/10 via-transparent to-transparent opacity-30" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <Mic2 className="w-12 h-12 text-accent mx-auto mb-4" />
          <h2 className="text-4xl md:text-5xl font-bold mt-2 mb-4 text-gradient">Musical Universe</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Blending genres to create unique sonic experiences that resonate with raw emotion
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {genres.map((genre, index) => (
            <motion.div
              key={genre.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-accent/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />
              <div className="relative bg-black/40 backdrop-blur-sm border border-accent/10 p-8 rounded-2xl hover:border-accent/30 transition-all duration-300">
                <div className="flex items-center gap-4 mb-4">
                  <div className="p-3 bg-accent/10 rounded-lg text-accent">
                    {genre.icon}
                  </div>
                  <h3 className="text-2xl font-bold text-gradient">{genre.name}</h3>
                </div>
                <p className="text-gray-400 mb-6">{genre.description}</p>
                <ul className="space-y-2">
                  {genre.elements.map((element, i) => (
                    <li key={i} className="flex items-center gap-2 text-sm text-gray-300">
                      <span className="w-1.5 h-1.5 bg-accent rounded-full" />
                      {element}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          viewport={{ once: true }}
          className="mt-20 text-center"
        >
          <p className="text-xl text-gray-400 italic max-w-2xl mx-auto">
            "Every genre is a different color in my palette, allowing me to paint the full spectrum of human emotion"
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default GenreShowcase;