import { motion } from "framer-motion";
import { Music4, Heart, Bot, Sparkles } from "lucide-react";

const About = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-black to-accent/5 py-20 relative">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-accent/10 via-transparent to-transparent opacity-30" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto"
        >
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Heart className="w-12 h-12 text-accent mx-auto mb-4 animate-pulse" />
            <h2 className="text-4xl md:text-5xl font-bold mt-2 mb-4 text-gradient tracking-tight">The Story Behind The Sound</h2>
            <p className="text-xl text-gray-400">Transforming Digital Emotions into Universal Experiences</p>
          </motion.div>
          
          <div className="space-y-16">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2, duration: 0.8 }}
              viewport={{ once: true }}
              className="prose prose-invert max-w-none"
            >
              <motion.div 
                className="bg-black/40 backdrop-blur-sm border border-accent/10 p-8 rounded-2xl hover:border-accent/30 transition-colors duration-300"
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="flex items-center gap-3 mb-4">
                  <Bot className="w-6 h-6 text-accent animate-pulse" />
                  <h3 className="text-xl font-bold text-gradient">AI-Enhanced Creativity</h3>
                </div>
                <p className="text-xl leading-relaxed mb-6 text-gray-300">
                  At 19, I've discovered my artistic voice at the intersection of emotional expression and artificial intelligence. 
                  Using AI as a powerful tool, I transform raw emotions into compelling soundscapes, creating music that resonates 
                  with the depth of human experience while pushing the boundaries of digital creativity.
                </p>
              </motion.div>

              <div className="grid md:grid-cols-3 gap-8 mt-12">
                <motion.div
                  whileHover={{ y: -5, scale: 1.02 }}
                  className="bg-black/40 backdrop-blur-sm border border-accent/10 p-6 rounded-xl hover:border-accent/30 transition-all duration-300"
                >
                  <div className="flex items-center gap-3 mb-4">
                    <Music4 className="w-6 h-6 text-accent" />
                    <h3 className="text-xl font-bold">Producer</h3>
                  </div>
                  <p className="text-gray-400">
                    Crafting AI-enhanced soundscapes that blend orchestral elements with modern metal
                  </p>
                </motion.div>

                <motion.div
                  whileHover={{ y: -5, scale: 1.02 }}
                  className="bg-black/40 backdrop-blur-sm border border-accent/10 p-6 rounded-xl hover:border-accent/30 transition-all duration-300"
                >
                  <div className="flex items-center gap-3 mb-4">
                    <Bot className="w-6 h-6 text-accent" />
                    <h3 className="text-xl font-bold">AI Artist</h3>
                  </div>
                  <p className="text-gray-400">
                    Using artificial intelligence to transform emotions into powerful musical expressions
                  </p>
                </motion.div>

                <motion.div
                  whileHover={{ y: -5, scale: 1.02 }}
                  className="bg-black/40 backdrop-blur-sm border border-accent/10 p-6 rounded-xl hover:border-accent/30 transition-all duration-300"
                >
                  <div className="flex items-center gap-3 mb-4">
                    <Sparkles className="w-6 h-6 text-accent" />
                    <h3 className="text-xl font-bold">Digital Artist</h3>
                  </div>
                  <p className="text-gray-400">
                    Creating immersive digital experiences through the fusion of AI and music
                  </p>
                </motion.div>
              </div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.8 }}
                viewport={{ once: true }}
                className="mt-16 bg-black/40 backdrop-blur-sm border border-accent/10 p-8 rounded-2xl hover:border-accent/30 transition-all duration-300"
                whileHover={{ scale: 1.02 }}
              >
                <h3 className="text-2xl font-bold mb-4 text-gradient">The Vision</h3>
                <p className="text-lg text-gray-300">
                  My goal is to pioneer the fusion of human emotion and artificial intelligence in music creation. 
                  Through this innovative approach, I craft experiences that bridge the gap between digital potential 
                  and human connection, creating a unique sonic landscape that speaks to the modern soul.
                </p>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;