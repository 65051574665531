import { Index } from "./pages/Index";
import { Label } from "./pages/Label";
import Imprint from "./pages/Imprint";

export const navItems = [
  {
    to: "/",
    page: <Index />,
  },
  {
    to: "/label",
    page: <Label />,
  },
  {
    to: "/imprint",
    page: <Imprint />,
  },
];
