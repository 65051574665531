import React from 'react';
import { motion } from "framer-motion";
import TrackCard from "./TrackCard";

const FeaturedTracks = ({ tracks, isLoading, hoveredTrack, setHoveredTrack }) => {
  const getFeaturedTracks = () => {
    if (!tracks || tracks.length === 0) return [];

    const sortedByDate = [...tracks].sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));
    const sortedByPopularity = [...tracks].sort((a, b) => (b.popularity || 0) - (a.popularity || 0));
    const sortedByStreams = [...tracks].sort((a, b) => (b.streams || 0) - (a.streams || 0));
    
    const newestTrack = { ...sortedByDate[0], isNewest: true };
    
    const secondTrack = sortedByPopularity[0].id === newestTrack.id 
      ? { ...sortedByStreams[0], isMostStreamed: true }
      : { ...sortedByPopularity[0], isMostPopular: true };

    return [newestTrack, secondTrack];
  };

  const featuredTracks = getFeaturedTracks();

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center space-y-4">
        <motion.div 
          className="w-16 h-16 border-4 border-accent rounded-full border-t-transparent"
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        />
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-white/60 text-lg"
        >
          Loading Releases
        </motion.p>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto space-y-4 md:space-y-8">
      {featuredTracks.map((track) => (
        track && <TrackCard
          key={track.id}
          track={track}
          isHovered={hoveredTrack === track}
          onHover={() => setHoveredTrack(track)}
          onLeave={() => setHoveredTrack(null)}
        />
      ))}
    </div>
  );
};

export default FeaturedTracks;