import React from "react";
import { motion } from "framer-motion";
import { Music4, Star } from "lucide-react";

const timelineEvents = [
  {
    date: "July 2024",
    title: "The Beginning",
    description: "Started the musical journey as LogoFox",
    highlight: true
  },
  {
    date: "July 2024",
    title: "First Release",
    description: "Released my first Song \"Gefangen In Mir\"",
    highlight: false
  },
  {
    date: "July 2024",
    title: "First Album",
    description: "Released my first Album \"ECLIPSE\"",
    highlight: false
  },
  {
    date: "September 2024",
    title: "Peak Listeners",
    description: "Hit 5000 Listeners per month on Spotify",
    highlight: false
  },
  {
    date: "September 2024",
    title: "Most successful Song",
    description: "\"I'll take flight\" hit over 10.000 all-time Streams",
    highlight: false
  },
  {
    date: "October 2024",
    title: "First Collaboration",
    description: "Released my first Song \"Fucking Decline\" which was a collaboration with Max Core",
    highlight: false
  },
  {
    date: "Present",
    title: "Current Chapter",
    description: "Let's see what the future brings...",
    highlight: true
  }
];

const Timeline = () => {
  return (
    <section className="relative py-20 overflow-hidden" id="timeline-section">
      {/* Background Effects */}
      <div className="absolute inset-0 bg-gradient-radial from-accent/10 via-transparent to-transparent opacity-40" />
      
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto px-4"
      >
        <div className="text-center mb-16">
          <Music4 className="w-12 h-12 text-accent mx-auto mb-4" />
          <h2 className="text-4xl md:text-5xl font-bold text-gradient mb-4">Musical Journey</h2>
          <p className="text-xl text-gray-400">Charting the path of sonic evolution</p>
        </div>

        <div className="relative">
          {/* Vertical Line */}
          <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-gradient-to-b from-accent via-accent to-transparent -z-10" />

          {/* Timeline Events */}
          {timelineEvents.map((event, index) => (
            <motion.div
              key={event.date}
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className={`relative flex items-center justify-center mb-12 ${
                index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
              }`}
            >
              {/* Center Point (moved behind) */}
              <div className="absolute left-1/2 transform -translate-x-1/2 w-4 h-4 -z-10">
                <div className={`w-4 h-4 rounded-full ${
                  event.highlight 
                    ? "bg-accent animate-pulse-glow" 
                    : "bg-white/50"
                }`} />
              </div>

              {/* Content */}
              <div className="w-full md:w-5/12 px-4">
                <div className={`p-6 rounded-xl backdrop-blur-sm transition-all duration-300 hover:scale-105 ${
                  event.highlight 
                    ? "bg-gradient-to-r from-accent/20 to-transparent border border-accent/30" 
                    : "bg-black/40 border border-white/10"
                }`}>
                  <div className="flex items-center gap-2 mb-2">
                    {event.highlight && <Star className="w-4 h-4 text-accent animate-pulse" />}
                    <h3 className="text-xl font-bold text-white">{event.date}</h3>
                  </div>
                  <h4 className="text-lg font-semibold mb-2 text-gradient">{event.title}</h4>
                  <p className="text-gray-400">{event.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Timeline;
