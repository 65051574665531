import { useQuery } from '@tanstack/react-query';
import { toast } from "sonner";

const API_BASE_URL = 'https://api.music.logofoxofficial.de';

async function fetchTracks() {
  try {
    const response = await fetch(`${API_BASE_URL}/tracks`);

    if (!response.ok) {
      throw new Error('Failed to fetch tracks');
    }

    const data = await response.json();
    return data.tracks;
  } catch (error) {
    console.error('Error in fetchTracks:', error);
    throw error;
  }
}

export function useSpotifyTracks(options = {}) {
  return useQuery({
    queryKey: ['spotify-tracks'],
    queryFn: fetchTracks,
    staleTime: 1000 * 60 * 30, // 30 minutes
    cacheTime: 1000 * 60 * 60, // 1 hour
    retry: 3,
    retryDelay: 3000,
    onError: () => {
      toast.error("Releases were unable to load :( Try again later");
    },
    ...options,
  });
}